<template>
    <div class="container">


        <h2 class="head2 mb-5">{{Lang.get('link1')}}</h2>
        <div class="text-red bold Truculenta text-32" style="max-width:550px;margin:auto;" v-html="Lang.get('spot1')"></div>
        <div>
            <div>
                <img src="../assets/ykb.png" class="img-fluid" />
                <div class="Cloud-Text2">
                    <div v-html="Lang.get('text1')">

                    </div>
                </div>
            </div>

        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">
            <div style="max-width:550px;margin:auto;">
                <center>

                    <h4>Değerli Paydaşlarımız,</h4>
                    <p>
                        2020 yılı; insanlığın yakın geleceğine yön verecek ekonomik, toplumsal ve teknolojik değişim sürecinin miladı niteliğinde gelişmelere sahne oldu. 2020’de sosyal yaşamda ve mesleki anlamda tüm alışkanlıklarımızdan başlayarak, hepimizin hayata bakış açısını köklü biçimde değiştirecek bir deneyimi yaşadık. Fakat tüm zorlu koşullara rağmen, salgın karşısında adaptasyonumuzu güçlendirecek, yenilikçi adımlar attık. Beklenen ve öngörülemeyen birçok etkiyi içinde bulunduran bu durum, iş sürekliliğimizi ve bileşenlerinin önemini bir kez daha vurgulamış oldu.
                    </p>
                    <p>
                        Salgının ilk gününden itibaren, “insan sağlığını” odağımıza alarak öncelikle bütün çalışanlarımızı salgına karşı korumayı ve operasyonlarımızı kesintisiz bir şekilde devam ettirmeyi amaçladık. Faaliyetlerimizi sürdürürken de tedarikçilerimizden tüketicilerimize kadar değer zincirimizdeki tüm paydaşlarımıza katkı sağlamayı hedefledik.
                    </p>
                    <p>
                        Tüketicilerimizin güvenli ürünlere erişimini sağlamak ve çalışanlarımızın sağlığını korumak için, salgının ortaya çıkmasından bu yana örnek uygulamalar geliştirdik. Bu uygulamalarımızın sonucunda, Türkiye’deki tüm fabrikalarımız için “TSE COVID-19 Güvenli Üretim Belgesi” aldık.
                    </p>
                    <p>
                        Dijitalleşme, 2020 yılında en fazla mesai harcadığımız konulardan biri oldu. Dijitalleşme alanında birkaç yıldır oluşturduğumuz altyapının sağladığı güçle salgın sürecini büyük bir başarıyla yönettik. Dijital dönüşümü, tedarik zincirinden tüketiciye kadar uzanan, uçtan uca bir yaklaşımla ele aldık.
                    </p>
                    <p>
                        “İsrafsız şirket” modelimizle bugüne kadar kat ettiğimiz yolda, hem gelecek nesillere yaşanabilir bir dünya bırakılmasına katkı sağlamak hem de işimizin sürdürülebilirliğini devam ettirmek için çalışıyoruz. COVID-19 sürecinde de tedarik zincirinin, ürünlerimizi tüketicilerimize ulaştırmanın, tüketicilerimizi anlamanın, onların ihtiyaç ve değişen davranışlarına göre hareket etmenin ne kadar önemli olduğunun bilinciyle hareket ettik.
                    </p>
                    <p>
                        Üretirken daima çevreye ve gelecek nesillere karşı sorumluluklarımızı ön plana alıyoruz. Sürdürülebilirlik çalışmalarımızı 2014 yılında belirlediğimiz uzun vadeli hedeflerle yürütürken, Ülker Bisküvi 2015 yılından bu yana Borsa İstanbul Sürdürülebilirlik Endeksi’nde, 2016 yılından beri de küresel sürdürülebilirlik endeksi FTSE4Good Gelişmekte Olan Piyasalar Endeksi’nde yer alıyor. Tasarruf ve verimlilik anlayışından taviz vermeden, paydaşlarımızla birlikte büyümeye 2020 yılında da devam ettik.
                    </p>
                    <p>
                        Tüm dünya gibi ülkemiz için de 2020 zorlu bir yıl oldu. Ancak piyasaları canlandırmak üzere çıkartılan yasaların ve özellikle 2020’nin ikinci çeyreğinden sonra sanayi sektörünün başı çektiği büyüme oranlarının da katkısıyla 2021’de kayda değer bir toparlanma yaşanacağını umuyoruz. Ülker Bisküvi olarak 2021 yılında da ülkemizin kalkınma hedeflerine destek olup global rekabette güç kazanmak, bulunduğumuz pazarlarda güçlü konumumuzu korumak ve liderliklerimizi devam ettirmek için çalışacağız.
                    </p>
                    <p>
                        Şirketimiz ve ülkemiz için sağladığımız katma değere inanç ve desteklerini sürdüren tüm paydaşlarımıza kalpten teşekkürlerimi sunuyorum.
                    </p>

                    <strong>Mehmet Tütüncü</strong><br />
                    Yönetim Kurulu Başkanı
                </center>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div style="max-width:550px;margin:auto;">
                <center>

                    <h4>Dear Stakeholders,</h4>

                    <p>
                        2020 will be remembered as an important year in terms of overcoming the challenges we faced due to the pandemic, as well as in terms of global cooperation and solidarity. In such trying times, at Ülker Bisküvi, we completely fulfilled our responsibilities across all our processes, in production and in access to consumers. We kept our production processes going, supporting the economy. As the strategic sector during the period, we continued serving our country.
                    </p>
                    <p>
                        We implemented our pandemic measures dynamically by closely following the developments. We adopted revisions in our business processes in line with the evolving circumstances, thanks to our flexible and agile business model. And we maintained our contribution to our country’s economy through our production, exportation and recruitment processes.
                    </p>
                    <p>
                        Our priority has always been human health and we intensified our efforts when the pandemic began. We implemented the 14 rules advised by the Ministry of Health in all our work areas, including our factories, to protect the health of our employees, as well as their families. In the meantime, we took additional measures which we called “14+14.” With our responsibility to meet the requirements of consumers, particularly during the pandemic, we put all our efforts towards uninterrupted production at all business units and transferring the goods produced to 190 thousand retail locations – to our consumers – nationwide, through our strong distribution network.
                    </p>
                    <p>
                        We increased our revenue by 20.5%
                        In 2020, we significantly increased our profitability while achieving sustainable growth in the areas where we operate. We increased our consolidated revenue by 20.5% to TL 9.4 billion as well as our Earnings Before Interest, Tax, Depreciation and Amortization (EBITDA) by 24.4% to 17.2% EBITDA margin. We have continued our undisputed leadership in the overall confectionary market in Turkey. We lead our country in becoming a production and exportation base while increasing retail and profitability. We achieved a growth of 18.6% and 23.4% in our domestic and international operations respectively.
                    </p>
                    <p>
                        We reinforced our market leadership in the biscuits market in Saudi Arabia (KSA), where we operate two factories, while we strengthened our position in the MENA and Central Asia markets. We also achieved stable growth in our retail figures in Kazakhstan, our gateway to Central Asia. In addition to these achievements, we successfully completed 2020 with a very strong balance sheet and net debt ratios.
                    </p>
                    <p>
                        We contribute to our country’s economy through our exportation operations to more than 100 countries worldwide, through more than 9 thousand employees, and through our production operations at 10 factories, six in Turkey and four overseas. As our efficiency initiatives in Turkey lead to cost advantages, our new products strengthen our leading position in the related categories.
                    </p>
                    <p>
                        In 2020, we signed a loan agreement of
                        USD 455 million and issued Eurobond amounting to USD 650 million. In the first half of the year,
                        Ülker Bisküvi signed a USD 455 million loan agreement with the European Bank for Reconstruction and Development (EBRD) and seven international banks. In the second half of the year, Ülker Bisküvi concluded its first Eurobond issuance of USD 650 million with an overflow of subscription of USD 1.8 billion from over 130 international investors in 28 countries. Despite the challenging global economic conditions, the provision of long-term financing to our Company by international investors was an indication of trust in Turkey and our Company. Ülker Bisküvi will sustain its growth through an investment-oriented mentality based on a concrete financing model.
                    </p>
                    <p>
                        Innovation: A driving force in growth
                        As a result of our R&D activities, we present innovative products to our consumers for their satisfaction. We develop new products in line with the demand and requirements, while also realizing projects to improve and increase the variety of existing products. In 2020, we invested in our production facilities and launched and re-launched several innovative products under the Ülker Çikolata, Dido, Ülker Çikolatalı Gofret, Hanımeller, Albeni, O’lala, Biskrem, Ülker Kremalı and Çizi brands, in particular. In the 2019-2020, we launched more than 70 new products in the chocolate, biscuit and cake categories. In the past three years, we significantly increased the ratio of new products in retail figures, demonstrating that innovation is a driving force in our Company’s growth.


                    </p>

                    <strong>Mehmet Tütüncü</strong><br />
                    Yönetim Kurulu Başkanı
                </center>
            </div>


        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>
        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Yönetim Kurulu Başkanı-nın Mesajı.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Message from the Chairman.pdf" class="download">{{Lang.get('download')}}</a>
    </div>
 



</template>

<script>
    import lng from "../store/languages";  

    export default {
  name: 'Chairman',
        components: {},
        data() {
            return {
            Lang:lng,
            }
        }
}</script>
